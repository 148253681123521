import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, Polygon, Circle, Data, InfoWindow } from '@react-google-maps/api';
import ship from '../assets/icon/ship.png';
import icon_torre from '../assets/icon/subestacion.png';
import desalination from '../assets/icon/desalination.png';
import fuentesCo2 from '../assets/data/fuentes_co2.geojson';
import transmision from '../assets/data/lineas_transmision.geojson';
import oleoductos from '../assets/data/oleoductos.geojson';
import puertos from '../assets/data/puertoss.geojson';
import almacenamiento from '../assets/data/almacenamiento_combustible.geojson';

const containerStyle = {
  width: '100%',
  height: '800px'
};

const center = {
  lat: -35.675147,
  lng: -71.542969
};

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function MapSection({ onMapClick, 
  setIsDelimitingArea,  
  radiusFuentesCo2, 
  radiusTransmision, 
  radiusOleoductos,
  radiusPuertos,
  radiusEstanquesCom,
  setDistanciaPuerto,
  setDistanciaFuentesCo2,
  setDistanciaMatriz,
  setDistanciaOleoductos,
  setDistanciaEstanquesComb,
 }) 
  {
  const [markerCoords, setMarkerCoords] = useState(null);
  const [path, setPath] = useState([]);
  const [isDelimiting, setIsDelimiting] = useState(false);
  const [libraries] = useState(['geometry']);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [icons, setIcons] = useState({});
  const [distanceToClosestPort, setDistanceToClosestPort] = useState(0);
  const [distanceToClosestSource, setDistanceToClosestSource] = useState(0);
  const [distanceToClosestStorage, setDistanceToClosestStorage] = useState(0);
  const [distanceToClosestTransmissionLine, setDistanceToClosestTransmissionLine] = useState(0);
  const [distanceToClosestPipeline, setDistanceToClosestPipeline] = useState(0);
  const [sourcesData, setSourcesData] = useState([]);
  const [transmisionData, setTransmisionData] = useState([]);
  const [oleoductosData, setOleoductosData] = useState([]);
  const [puertosData, setPuertosData] = useState([]);
  const [almacenamientoData, setAlmacenamientoData] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  




  const onLoad = useCallback(() => {
    setMapLoaded(true);
    if (window.google && window.google.maps) {
      const shipIcon = {
        url: ship,
        scaledSize: new window.google.maps.Size(25, 25) // Ajusta el tamaño del ícono según sea necesario
      };
      const desalinationIcon = {
        url: desalination,
        scaledSize: new window.google.maps.Size(25, 25) // Ajusta el tamaño del ícono según sea necesario
      };
      const torreIcon = {
        url: icon_torre,
        scaledSize: new window.google.maps.Size(25, 25) // Ajusta el tamaño del ícono según sea necesario
      };
      setIcons({ shipIcon, desalinationIcon, torreIcon });
    }
  }, []);

  useEffect(() => {
    fetch(fuentesCo2)
      .then(response => response.json())
      .then(data => setSourcesData(data.features.map(feature => ({
        name: feature.properties.NOMBRE,
        lat: feature.geometry.coordinates[1],
        lng: feature.geometry.coordinates[0],
        propiedad: feature.properties.PROPIEDAD,
        capitania: feature.properties.CAPITA_PUE,
        operador: feature.properties.OPERADOR,
        tipo: feature.properties.TIPO,
        combustible: feature.properties.COMBUSTIBLE
      }))))
      .catch(error => console.error("Error loading fuentesCo2 GeoJSON:", error));

    fetch(transmision)
      .then(response => response.json())
      .then(data => setTransmisionData(data.features.map(feature => feature.geometry.coordinates.map(coord => ({
        lat: coord[1],
        lng: coord[0]
      })))))
      .catch(error => console.error("Error loading transmision GeoJSON:", error));

    fetch(oleoductos)
      .then(response => response.json())
      .then(data => setOleoductosData(data.features.map(feature => feature.geometry.coordinates.map(coord => ({
        lat: coord[1],
        lng: coord[0]
      })))))
      .catch(error => console.error("Error loading oleoductos GeoJSON:", error));

    fetch(puertos)
      .then(response => response.json())
      .then(data => setPuertosData(data.features.map(feature => ({
        name: feature.properties.NOMBRE,
        lat: feature.geometry.coordinates[1],
        lng: feature.geometry.coordinates[0],
        propiedad: feature.properties.PROPIEDAD,
        capitania: feature.properties.CAPITA_PUE,
        operador: feature.properties.OPERADOR,
        tipo: feature.properties.TIPO,
        combustible: feature.properties.COMBUSTIBLE
      }))))
      .catch(error => console.error("Error loading puertos GeoJSON:", error));

    fetch(almacenamiento)
      .then(response => response.json())
      .then(data => setAlmacenamientoData(data.features.map(feature => ({
        name: feature.properties.NOMBRE,
        lat: feature.geometry.coordinates[1],
        lng: feature.geometry.coordinates[0],
        propiedad: feature.properties.PROPIEDAD,
        tipo: feature.properties.TIPO,
        capacidad: feature.properties.CAPACIDAD
      }))))
      .catch(error => console.error("Error loading almacenamiento GeoJSON:", error));
  }, []);

  const calculateDistance = (markerLatLng, locations) => {
    let minDistance = Infinity;
    locations.forEach(location => {
      const locationLatLng = new window.google.maps.LatLng(location.lat, location.lng);
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(markerLatLng, locationLatLng) / 1000; // Convertir a kilómetros
      if (distance < minDistance) {
        minDistance = distance;
      }
    });
    return Math.round(minDistance) // Redondear 
  };

  const calculateDistanceToLine = (markerLatLng, lineCoords) => {
    let minDistance = Infinity;
    const google = window.google;
  
    for (let i = 0; i < lineCoords.length - 1; i++) {
      const segmentStart = new google.maps.LatLng(lineCoords[i].lat, lineCoords[i].lng);
      const segmentEnd = new google.maps.LatLng(lineCoords[i + 1].lat, lineCoords[i + 1].lng);
  
      const u = ((markerLatLng.lat() - segmentStart.lat()) * (segmentEnd.lat() - segmentStart.lat()) +
                 (markerLatLng.lng() - segmentStart.lng()) * (segmentEnd.lng() - segmentStart.lng())) /
                ((segmentEnd.lat() - segmentStart.lat()) ** 2 + (segmentEnd.lng() - segmentStart.lng()) ** 2);
  
      let closestPoint;
      if (u < 0) {
        closestPoint = segmentStart;
      } else if (u > 1) {
        closestPoint = segmentEnd;
      } else {
        closestPoint = new google.maps.LatLng(
          segmentStart.lat() + u * (segmentEnd.lat() - segmentStart.lat()),
          segmentStart.lng() + u * (segmentEnd.lng() - segmentStart.lng())
        );
      }
  
      const distance = google.maps.geometry.spherical.computeDistanceBetween(markerLatLng, closestPoint);
      if (distance < minDistance) {
        minDistance = distance;
      }
    }
  
    return Math.round(minDistance / 1000); // Convertir a kilómetros y redondear a dos decimales
  };

  const handleMapClick = useCallback((event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const markerLatLng = new window.google.maps.LatLng(lat, lng);

    const portDistance = calculateDistance(markerLatLng, puertosData);
    const sourceDistance = calculateDistance(markerLatLng, sourcesData);
    const storageDistance = calculateDistance(markerLatLng, almacenamientoData);
    const transmissionLineDistances = transmisionData.map(line => calculateDistanceToLine(markerLatLng, line));
    const closestTransmissionLineDistance = Math.min(...transmissionLineDistances);
    const pipelineDistances = oleoductosData.map(line => calculateDistanceToLine(markerLatLng, line));
    const closestPipelineDistance = Math.min(...pipelineDistances);

    //pasamos la distancia del puerto mas cercano a cotizacion
    setDistanceToClosestPort(portDistance);
    setDistanciaPuerto(portDistance);
     //pasamos la distancia de las Fuentes de co2 mas cercano a cotizacion
    setDistanceToClosestSource(sourceDistance);
    setDistanciaFuentesCo2(sourceDistance);
    //pasamos la distancia de los Estanques de combustible mas cercano a cotizacion
    setDistanceToClosestStorage(storageDistance);
    setDistanciaEstanquesComb(storageDistance);
    //pasamos la distancia de la Matriz energetica mas cercana a cotizacion
    setDistanceToClosestTransmissionLine(closestTransmissionLineDistance);
    setDistanciaMatriz(closestTransmissionLineDistance);
    //pasamos la distancia del oleoducto mas cercano a cotizacion
    setDistanceToClosestPipeline(closestPipelineDistance);
    setDistanciaOleoductos(closestPipelineDistance);

    if (isDelimiting) {
      const newPath = [...path, { lat, lng }];
      setPath(newPath);

      if (newPath.length > 2) {
        const google = window.google;
        const bounds = new google.maps.LatLngBounds();
        newPath.forEach((point) => bounds.extend(point));
        const center = bounds.getCenter();
        
        const areaValue = google.maps.geometry.spherical.computeArea(newPath.map(p => new google.maps.LatLng(p.lat, p.lng)));
        
        onMapClick(center.lat(), center.lng(), areaValue);
      }
    } else {
      setMarkerCoords({ lat, lng });
      onMapClick(lat, lng, 0);
    }
  }, [isDelimiting, path, onMapClick, puertosData, sourcesData, almacenamientoData, transmisionData, oleoductosData]);

  const toggleDelimitingMode = () => {
    setIsDelimiting(!isDelimiting);
    setIsDelimitingArea(!isDelimiting);
    if (!isDelimiting) {
      setPath([]);
    }
  };

  const clearAll = () => {
    setMarkerCoords(null);
    setPath([]);
    onMapClick(0, 0, 0);
  };

  const handleDataLoadError = (error) => {
    console.error("Error loading GeoJSON:", error);
  };

  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries} onLoad={onLoad} loadingElement={<div>Cargando...</div>}>
      {mapLoaded && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={5}
        onClick={handleMapClick}
      >
        {markerCoords && <Marker position={markerCoords} />}
        {path.length > 0 && (
          <Polygon
            paths={path}
            options={{
              fillColor: "#00FF00",
              fillOpacity: 0.2,
              strokeColor: "#0000FF",
              strokeOpacity: 0.8,
              strokeWeight: 2,
            }}
          />
        )}
  
        {puertosData.map((puerto, index) => (
          radiusPuertos > 0 && (
            <React.Fragment key={index}>
              <Circle
                center={{ lat: puerto.lat, lng: puerto.lng }}
                radius={radiusPuertos * 1000} // Convertir km a metros
                options={{
                  fillColor: "#FF0000",
                  fillOpacity: 0.1,
                  strokeColor: "#FF0000",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  clickable: false // Desactivar eventos de clic en el círculo
                }}
              />
              <Marker
                position={{ lat: puerto.lat, lng: puerto.lng }}
                icon={icons.shipIcon}
                onClick={() => setSelectedMarker(puerto)}
              />
            </React.Fragment>
          )
        ))}

        {/* Cargar el GeoJSON para las líneas de transmisión */}
        {radiusTransmision > 0 && (
          <Data
            options={{
              controlPosition: window.google.maps.ControlPosition.TOP_LEFT,
              style: {
                strokeColor: '#FFFF00', // Amarillo
                strokeWeight: 2
              }
            }}
            onLoad={dataLayer => {
              dataLayer.loadGeoJson(transmision, null, handleDataLoadError);
            }}
          />
        )}

        {/* Cargar el GeoJSON para las líneas de oleoductos */}
        {radiusOleoductos > 0 && (
          <Data
            options={{
              controlPosition: window.google.maps.ControlPosition.TOP_LEFT,
            }}
            onLoad={dataLayer => {
              dataLayer.loadGeoJson(oleoductos, null, handleDataLoadError);
            }}
          />
        )}

        {/* Mostrar fuentes de CO2 como marcadores con íconos personalizados */}
        {sourcesData.map((source, index) => (
          radiusFuentesCo2 > 0 && (
            <React.Fragment key={index}>
              <Circle
                center={{ lat: source.lat, lng: source.lng }}
                radius={radiusFuentesCo2 * 1000} // Convertir km a metros
                options={{
                  fillColor: "#00FF00",
                  fillOpacity: 0.1,
                  strokeColor: "#00FF00",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  clickable: false // Desactivar eventos de clic en el círculo
                }}
              />
              <Marker
                position={{ lat: source.lat, lng: source.lng }}
                icon={icons.torreIcon}
                title={source.name}
                onClick={() => setSelectedMarker(source)}
              />
            </React.Fragment>
          )
        ))}

        {/* Mostrar almacenamiento como marcadores con íconos personalizados */}
        {almacenamientoData.map((storage, index) => (
          radiusEstanquesCom > 0 && (
            <React.Fragment key={index}>
              <Circle
                center={{ lat: storage.lat, lng: storage.lng }}
                radius={radiusEstanquesCom * 1000} // Convertir km a metros
                options={{
                  fillColor: "#FFA500", // Naranja
                  fillOpacity: 0.1,
                  strokeColor: "#FFA500",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  clickable: false // Desactivar eventos de clic en el círculo
                }}
              />
              <Marker
                position={{ lat: storage.lat, lng: storage.lng }}
                icon={icons.desalinationIcon} // Usar el icono de desalination o agregar uno nuevo para almacenamiento
                title={storage.name}
                onClick={() => setSelectedMarker(storage)}
              />
            </React.Fragment>
          )
        ))}

        {selectedMarker && (
          <InfoWindow
            position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div>
              <h2>{selectedMarker.name}</h2>
              {selectedMarker.propiedad && <p>Propiedad: {selectedMarker.propiedad}</p>}
              {selectedMarker.capitania && <p>Capitanía: {selectedMarker.capitania}</p>}
              {selectedMarker.operador && <p>Operador: {selectedMarker.operador}</p>}
              {selectedMarker.tipo && <p>Tipo: {selectedMarker.tipo}</p>}
              {selectedMarker.combustible && <p>Combustible: {selectedMarker.combustible}</p>}
              {selectedMarker.capacidad && <p>Capacidad: {selectedMarker.capacidad}</p>}
            </div>
          </InfoWindow>
        )}
     
        <button className="bg-secondary text-black px-4 py-2 rounded hover:bg-secondary-600"
          style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 1 }}
          onClick={toggleDelimitingMode}
        >
          {isDelimiting ? 'Marcar punto' : 'Delimitar zona'}
        </button>
        <button className="bg-secondary text-black px-4 py-2 rounded hover:bg-secondary-600"
          style={{ position: 'absolute', top: '10px', left: '150px', zIndex: 1 }}
          onClick={clearAll}
        >
          Limpiar
        </button>
        {distanceToClosestPort && (
          <div style={{ position: 'absolute', bottom: '10px', left: '10px', zIndex: 1, backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '10px', borderRadius: '5px' }}>
            Distancia al puerto más cercano: {distanceToClosestPort} km
          </div>
        )}
        {distanceToClosestSource && (
          <div style={{ position: 'absolute', bottom: '50px', left: '10px', zIndex: 1, backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '10px', borderRadius: '5px' }}>
            Distancia a la fuente de CO2 más cercana: {distanceToClosestSource} km
          </div>
        )}
        {distanceToClosestStorage && (
          <div style={{ position: 'absolute', bottom: '90px', left: '10px', zIndex: 1, backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '10px', borderRadius: '5px' }}>
            Distancia al almacenamiento más cercano: {distanceToClosestStorage} km
          </div>
        )}
        {distanceToClosestTransmissionLine && (
          <div style={{ position: 'absolute', bottom: '130px', left: '10px', zIndex: 1, backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '10px', borderRadius: '5px' }}>
            Distancia a la línea de transmisión más cercana: {distanceToClosestTransmissionLine} km
          </div>
        )}
        {distanceToClosestPipeline && (
          <div style={{ position: 'absolute', bottom: '170px', left: '10px', zIndex: 1, backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '10px', borderRadius: '5px' }}>
            Distancia al oleoducto más cercano: {distanceToClosestPipeline} km
          </div>
        )}
      </GoogleMap>
      )}
    </LoadScript>
  );
}

export default MapSection;
