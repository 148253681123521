import Zonificador from './Zonificador'; 
import React, { useState,useEffect} from 'react';
import 'chart.js';
import { Chart, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import Cargando from './Cargando';
import { Costos } from './Costos';
import Produccion from './Produccion';
import Resultados from './Resultados';

Chart.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);





function Cotizacion1({ 
    latitud, 
    longitud, 
    area, 
    isDelimitingArea = false,
    activeSection ,
    setIsDataLoaded,
    setRadiusDisponibilidadAguaReciclada,
    radiusDisponibilidadAguaReciclada,
    setRadiusOleoductos,
    setRadiusSubestaciones,
    setRadiusTransmision,
    radiusSubestaciones,
    radiusOleoductos,
    radiusTransmision,
    setRadiusEstanquesCom,
    setRadiusFuentesCo2,
    setRadiusPuertos,
    radiusPuertos,
    radiusEstanquesCom,
    radiusFuentesCo2,
    distanciaPuerto,
    distanciaFuentesCo2,
    distanciaMatriz,
    distanciaOleoductos,
    distanciaEstanquesComb,
}) {
        
    const [fuente, setFuente] = useState('Hibrido');
    const [solarMW, setSolarMW] = useState('10');
    const [vientoMW, setVientoMW] = useState('10');
    
    const [longitudState, setLongitud] = useState(longitud);
    const [areaState, setArea] = useState(area);
    const [latitudState, setLatitud] = useState(latitud);
    const [precioH2, setPrecioH2] = useState('2');
    const [precioH2O, setPrecioH2O] = useState('0');
    const [precioCo2, setPrecioCO2] = useState('45');
    const [combustible, setCombustible] = useState('');
    const [precioelectricidad, setPrecioElectricidad] = useState('50');
    const [factorplanta, setFactorPlanta] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [paneles, setPaneles] = useState(0);
    const [aeros, setAeros] = useState(0); 

    //Nuevas
    const [porcentajeOpexFijo, setPorcentajeOpexFijo] = useState(0);
    const [porcentajeOpexDirecto, setPorcentajeOpexDirecto] = useState(0);
    const [porcentajeHidrogeno, setPorcentajeHidrogeno] = useState(0);
    const [porcentajeCO2, setPorcentajeCO2] = useState(0);
    const [porcentajeElectricidad, setPorcentajeElectricidad] = useState(0);
    const [porcentajeCapex, setPorcentajeCapex] = useState(0);
    const [areaTerreno, setAreaTerreno] = useState(0);
    const [cantidadPaneles, setCantidadPaneles] = useState(0);
    const [cantidadAerogeneradores, setCantidadAerogeneradores] = useState(0);
    const [mwSolInstalados, setMwSolInstalados] = useState(0);
    const [mwVientoInstalados, setMwVientoInstalados] = useState(0);


     // Agrega estados para los porcentajes
    const [solarPercentage, setSolarPercentage] = useState(50); // Valor por defecto#BORAR
    const [windPercentage, setWindPercentage] = useState(50); // Valor por defecto#BORAR
        // Realizar los cálculos de porcentaje si isDelimitingArea es true
    const [DelimitingArea, setIsDelimitingArea] = useState(isDelimitingArea);
    //distancias a las variables
    const [DistanciaPuerto, setDistanciaPuerto] = useState(distanciaPuerto);
    const [DistanciaFuentesCo2, setDistanciaFuentesCo2] = useState(distanciaFuentesCo2);
    const [DistanciaOleoducto, setDistanciaOleoducto] = useState(distanciaOleoductos);
    const [DistanciaMatriz, setDistanciaMatriz] = useState(distanciaMatriz);
    const [DistanciaEstanquesComb, setDistanciaEstanquesComb] = useState(distanciaEstanquesComb);
    //Modelo
    const [modeloAerogenerador, setModeloAerogenerador] = useState('Siemens SWT-3.6-120');
    const [factorPerdidas, setFactorPerdidas] = useState(1);
    const [alturaSuperficie, setAlturaSuperficie] = useState(80);
    const [tipoArreglo, setTipoArreglo] = useState('hsat');
    const [azimut, setAzimut] = useState(0);
    const [inclinacionMaximaHSAT, setInclinacionMaximaHSAT] = useState(45);
    const [anguloElevacion, setAnguloElevacion] = useState(99);
    const [cobertura, setCobertura] = useState('glass');
    const [perdidas, setPerdidas] = useState(14);
    const [showInfo, setShowInfo] = useState(false);
    const [graph, setGraph] = useState(null);
    const [jetfuelsinh, setJetfuelsinh] = useState(0);
    const [co2sinh, setCo2sinh] = useState(0);
    const [h2osinh, setH2osinh] = useState(0);
    const [h2_sin_electrolisis, setH2SinElectrolisis] = useState(0);
    const [lcof_jetfuel_sinh, setLcofJetfuelSinh] = useState(0);
    const [hideHydrolysisResults, setHideHydrolysisResults] = useState(false);
    const [graphsinh, setGraphSinh] = useState(null);
    const [aeroCalculadosMarcador, setAeroCalculadosMarcador] = useState(null);
    const [añosProyecto, setAñosProyecto] = useState(30);
    const [disponibilidad, setDisponibilidad] = useState(97);
    const[tasaDescuento, setTasaDescuento]=useState(7); 
    const [puntaje_ranking, setPuntaje_ranking] = useState(0);
    const [porcentajes_zonificador, setPorcentajes_zonificador] = useState(0);

    const [desviacion_estandar_factor_planta, setDesviacionEstandarFactorPlanta] = useState(0);
    const [arreglo_factor_planta_horario, setArregloFactorPlantaHorario] = useState([]);
    const [arreglo_factor_planta_mensual, setArregloFactorPlantaMensual] = useState([]);

    const [produccion_minima_mensual_jetfuel_sin_electrolisis, setProduccionMinimaMensualJetFuelSinElectrolisis] = useState(0);
    const [produccion_maxima_mensual_jetfuel_sin_electrolisis, setProduccionMaximaMensualJetFuelSinElectrolisis] = useState(0);
    const [desviacion_estandar_jetfuel_sin_electrolisis, setDesviacionEstandarJetFuelSinElectrolisis] = useState(0);
    const [arreglo_produccion_horaria_jetfuel_sin_electrolisis, setArregloProduccionHorariaJetFuelSinElectrolisis] = useState([]);
    const [arreglo_produccion_mensual_co2_sin_electrolisis, setArregloProduccionMensualCo2SinElectrolisis] = useState([]);
    const [arreglo_produccion_horaria_co2_sin_electrolisis, setArregloProduccionHorariaCo2SinElectrolisis] = useState([]);
    const [arreglo_produccion_mensual_h2o_sin_electrolisis, setArregloProduccionMensualH2oSinElectrolisis] = useState([]);
    const [arreglo_produccion_horaria_h2o_sin_electrolisis, setArregloProduccionHorariaH2oSinElectrolisis] = useState([]);
    const [arreglo_produccion_mensual_h2_sin_electrolisis, setArregloProduccionMensualH2SinElectrolisis] = useState([]);
    const [arreglo_produccion_horaria_h2_sin_electrolisis, setArregloProduccionHorariaH2SinElectrolisis] = useState([]);
    const [factor_planta_sol, setFactorPlantaSol] = useState(0);
    const [factor_planta_viento, setFactorPlantaViento] = useState(0);
    const [porcentaje_solar, setPorcentajeSolar] = useState(0);
    const [porcentaje_eolico, setPorcentajeEolico] = useState(0);
    const [numeroCiclos, setNumeroCiclos] = useState(3500)
    const [eficienciaBateria, setEficienciaBateria] = useState(97)
    const [selfDischarge, setSelfDischarge] = useState(0.008)
    const [bateriaCapacidad, setBateriaCapacidad] = useState()
    const [potenciaReactor, setPotenciaReactor] = useState(0)
    const [energiaNoAlmacenadaAnual, setEnergiaNoAlmacenadaAnual] = useState(0)
    const [porcentajeHorasAnualesReactorNoAlCien, setPorcentajeHorasAnualesReactorNoAlCien] = useState(0)
    const [porcentajeHorasAnualesSinEnergiaSuficienteReactor, setPorcentajeHorasAnualesSinEnergiaSuficienteReactor] = useState(0)
    


    //Radios zonificador
    const[radio1, setRadio1]=useState(25);
    // Estado para el radio seleccionado
    const [selectedRadius, setSelectedRadius] = useState(100); // Ejemplo inicial

    // Función para actualizar el estado basado en la selección en Zonificador
    const handleRadiusChange = (newRadius) => {
        setSelectedRadius(newRadius);
    };



        const toggleInfo = () => {
        setShowInfo(!showInfo);
        };
        

        // Actualiza el porcentaje de viento cuando cambia el porcentaje de solar

        useEffect(() => {
        if (fuente === "Hibrido" && isDelimitingArea) {
        setWindPercentage(100 - solarPercentage);
        }
        
    }, [solarPercentage, fuente, isDelimitingArea]);

        
    useEffect(() => {
        if (fuente === "solar") {
            setVientoMW('0');
        } else if (fuente === "viento") {
            setSolarMW('0');
        }
    }, [fuente]);
    


    // Actualiza el porcentaje de solar cuando cambia el porcentaje de viento
    useEffect(() => {
        if (fuente === "Hibrido" && isDelimitingArea) {
        setSolarPercentage(100 - windPercentage);
        }
    }, [windPercentage, fuente, isDelimitingArea]);
    

      // Cambia las entradas basadas en el modo
    useEffect(() => {
        if (isDelimitingArea) {
        if (fuente === "solar") {
            setSolarPercentage(100);
            setWindPercentage(0);
        } else if (fuente === "viento") {
            setSolarPercentage(0);
            setWindPercentage(100);
        }
        }
    }, [fuente, isDelimitingArea]);
    


    useEffect(() => {
        setLongitud(longitud);
        setLatitud(latitud);
        setArea(area);
        setIsDelimitingArea(isDelimitingArea);
        setDistanciaPuerto(distanciaPuerto)
        setDistanciaEstanquesComb(distanciaEstanquesComb);
        setDistanciaFuentesCo2(distanciaFuentesCo2);
        setDistanciaMatriz(distanciaMatriz);
        setDistanciaOleoducto(distanciaOleoductos);
    }, [longitud, latitud,area,isDelimitingArea,distanciaPuerto,distanciaEstanquesComb,distanciaFuentesCo2,distanciaMatriz,distanciaOleoductos]); 
    


    const calcularResultado = () => {
        setCargando(true);  // Iniciar la carga
        setIsDataLoaded(false);
        console.log("La función calcularResultado se está ejecutando");
        console.log("Distancia",distanciaPuerto)
        console.log(isDelimitingArea)
        console.log(windPercentage)
        
        // La función para calcular el rotor
        let calculatedRotor = 0;
        let potencia_aero=0
        
        if (modeloAerogenerador === "Siemens SWT-3.6-120") {
            calculatedRotor = 100;
            potencia_aero=3.6;
        } else if (modeloAerogenerador === "Sinovel SL3000/100") {
            calculatedRotor = 100;
            potencia_aero=3;
        } else if (modeloAerogenerador === "Vestas V164 - 8.0 MW") {
            calculatedRotor = 164;
            potencia_aero=8;
        } else if (modeloAerogenerador === "Acciona AW 77/1500 Class III") {
            calculatedRotor = 77;
            potencia_aero=1.5;
        } else if (modeloAerogenerador === "SDeWind D8.2") {
            calculatedRotor = 80;
            potencia_aero=2;
        } else if (modeloAerogenerador === "Gamesa G128-4.5 MW") {
            calculatedRotor = 128;
            potencia_aero=4.5;
        } else if (modeloAerogenerador === "Gamesa G132-5 MW") {
            calculatedRotor = 132;
            potencia_aero=5;
        } else if (modeloAerogenerador === "Sinovel SL6000/128 Onshore") {
            calculatedRotor = 128;
            potencia_aero=6;
        } else if (modeloAerogenerador === "WinWinD WWD-1-60") {
            calculatedRotor = 60;
            potencia_aero=1;
        } else if (modeloAerogenerador === "Vestas V90 - 3.0 MW 109.4 dB(A)") {
            calculatedRotor = 90;
            potencia_aero=3;
        }

        const panelesCalculados = (area*(solarPercentage / 100) * 0.8)/2;
        
        const numeroAreas=Math.floor((area* (windPercentage / 100))/(calculatedRotor*7*calculatedRotor*4));
        // Inicializamos aeroCalculados
        let aeroCalculados=0;

        // Verificamos las condiciones y asignamos el valor correspondiente a aeroCalculados
        if (windPercentage === 0) {
            aeroCalculados = 0;
        } else if (numeroAreas === 0) {
            aeroCalculados = 1;
        } else if (numeroAreas === 1) {
            aeroCalculados = 5;
        } else if (numeroAreas > 1 && numeroAreas % 2 !== 0) {
            // Impar y mayor que 1
            aeroCalculados = (8 + (5*(((numeroAreas+1)/2)-1)))-2;
        } else if (numeroAreas % 2 === 0) {
            // Par
            aeroCalculados = (8 + (5*((numeroAreas/2)-1)));
        }
        

        
        const mwdisponiblesolCalculados = Math.floor((panelesCalculados * 500) / 100000);
        const mwdisponiblevientoCalculados = Math.floor((aeroCalculados * potencia_aero));
        
        // Calculos a partir de MW

        const aeroCalculadosMarcador = Math.floor(vientoMW / potencia_aero);
        
        let numeroAreasMarcador = 0;
        let windPercentageMarcador = 0;

        if (aeroCalculadosMarcador === 0) {
        windPercentageMarcador  = 0;
        numeroAreasMarcador = 0;
        } else if (aeroCalculadosMarcador === 1) {
        windPercentageMarcador  = 0;
        numeroAreasMarcador = 0;
        } else if (aeroCalculadosMarcador <= 5) {
        windPercentageMarcador  = 100;
        numeroAreasMarcador = 1;
        } else if (
        2 * (((aeroCalculadosMarcador - 8 + 2) / 5) + 1) - 1 > 1 &&
        (2 * (((aeroCalculadosMarcador - 8 + 2) / 5) + 1) - 1) % 2 !== 0
        ) {
        windPercentageMarcador  = 100;
        numeroAreasMarcador = 2 * (((aeroCalculadosMarcador - 8 + 2) / 5) + 1) - 1;
        } else if ((2 * ((aeroCalculadosMarcador - 8) / 5 + 1)) % 2 === 0) {
        windPercentageMarcador  = 100;
        numeroAreasMarcador = ((2 * ((aeroCalculadosMarcador - 8) / 5 + 1)) % 2 === 0) % 2 === 0;
        }
        
        
        
        // Calcular el área total de viento
        let areaTotalViento = 0;

        if (windPercentage !== 0) {
        areaTotalViento = (numeroAreasMarcador * calculatedRotor * 7 * calculatedRotor * 4);
        }

        // Calcular paneles solares calculados
        const panelesCalculadosMarcador = (solarMW * 1000000) / 500;
        

        const areaTotalSolar = (panelesCalculadosMarcador * 2) / 0.8;

        // Calcular el área total marcador
        const areaTotal = areaTotalSolar + areaTotalViento;
        const areaMarcador = isDelimitingArea ? area : areaTotal;

   

        
        setAeroCalculadosMarcador(aeroCalculadosMarcador)
        setArea(areaMarcador)
        

            // Ajusta los valores de solarMW y vientoMW basados en si se está delimitando el área
        const solarMWCalculado = isDelimitingArea ? mwdisponiblesolCalculados : solarMW;
        const vientoMWCalculado = isDelimitingArea ? mwdisponiblevientoCalculados : vientoMW;
        const paneles=isDelimitingArea ? panelesCalculados : panelesCalculadosMarcador;
        const aeros=isDelimitingArea ? aeroCalculados: aeroCalculadosMarcador;
        setPaneles(paneles)
        setAeros(aeros)
        setSolarMW(solarMWCalculado)
        setVientoMW(vientoMWCalculado)

        console.log(vientoMWCalculado)
        console.log(solarMWCalculado)
        console.log(solarMW)
        console.log(vientoMW)
        console.log(areaState)
        


        
    
  
    //MANDAMOS Y RECIBIMOS LOS DATOS
        const dataToSend = {
            longitud: longitudState,
            latitud: latitudState,
            cantidadMWsol: solarMWCalculado.toString(),
            cantidadMWviento: vientoMWCalculado.toString(),
            factorPerdidas:factorPerdidas,
            alturaSuperficie:alturaSuperficie,
            tipoArreglo:tipoArreglo,
            azimut:azimut,
            inclinacionMaximaHSAT:inclinacionMaximaHSAT,
            anguloElevacion:anguloElevacion,
            cobertura:cobertura,
            perdidas:perdidas,
            modeloAerogenerador:modeloAerogenerador,
            precioCo2: precioCo2,
            precioH2:precioH2,
            precioH2O:precioH2O,
            precioelectricidad:precioelectricidad,
            area:areaState,
            solarPercentage:solarPercentage,
            windPercentage:windPercentage,
            isDelimitingArea:DelimitingArea,
            disponibilidad:disponibilidad,
            tasaDescuento:tasaDescuento,
            añosProyecto:añosProyecto,
            distanciaPuerto:DistanciaPuerto,
            distanciaEstanquesComb:DistanciaEstanquesComb,
            distanciaFuentesCo2:DistanciaFuentesCo2,
            distanciaMatriz:DistanciaMatriz,
            distanciaOleoductos:DistanciaOleoducto,
            numeroCiclos:numeroCiclos,
            eficienciaBateria:eficienciaBateria,
            selfDischarge:selfDischarge

            

        };
        console.log(dataToSend)
        fetch('https://efueltool-145738760340.southamerica-east1.run.app/enviar_datos', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSend)  // Convertir el objeto a una cadena JSON.
        })
        .then(res => {
            if (!res.ok) {
                throw new Error("Error en la respuesta del servidor: " + res.statusText);
            }
            return res.json();
        })
        .then(data => {
            setFactorPlanta(data.factor_planta_anual);
            setJetfuelsinh(data.jetfuel_m3_año_sin_electrolisis); // Agrega esta línea
            setCo2sinh(data.co2_toneladas_año_sin_electrolisis);         // Agrega esta línea
            setH2osinh(data.h2o_m3_año_sin_electrolisis);
            setH2SinElectrolisis(data.h2_toneladas_año_sin_electrolisis)        // Agrega esta línea
            setLcofJetfuelSinh(data.lcof_jetfuel_sin_electrolisis);
            setGraphSinh(data.graph_jetfuel_mensual_sin_electrolisis);
            setPuntaje_ranking(data.puntaje_ranking);
            setPorcentajes_zonificador(data.porcentajes_zonificador);

            setDesviacionEstandarFactorPlanta(data.desviacion_estandar_factor_planta);
            setArregloFactorPlantaHorario(data.arreglo_factor_planta_horario);
            setArregloFactorPlantaMensual(data.arreglo_factor_planta_mensual);
            setProduccionMinimaMensualJetFuelSinElectrolisis(data.produccion_minima_mensual_jetfuel_sin_electrolisis);
            setProduccionMaximaMensualJetFuelSinElectrolisis(data.produccion_maxima_mensual_jetfuel_sin_electrolisis);
            setDesviacionEstandarJetFuelSinElectrolisis(data.desviacion_estandar_jetfuel_sin_electrolisis);
            setArregloProduccionHorariaJetFuelSinElectrolisis(data.arreglo_produccion_horaria_jetfuel_sin_electrolisis);
            setArregloProduccionMensualCo2SinElectrolisis(data.arreglo_produccion_mensual_co2_sin_electrolisis);
            setArregloProduccionHorariaCo2SinElectrolisis(data.arreglo_produccion_horaria_co2_sin_electrolisis);
            setArregloProduccionMensualH2oSinElectrolisis(data.arreglo_produccion_mensual_h2o_sin_electrolisis);
            setArregloProduccionHorariaH2oSinElectrolisis(data.arreglo_produccion_horaria_h2o_sin_electrolisis);
            setArregloProduccionMensualH2SinElectrolisis(data.arreglo_produccion_mensual_h2_sin_electrolisis);
            setArregloProduccionHorariaH2SinElectrolisis(data.arreglo_produccion_horaria_h2_sin_electrolisis);
            setFactorPlantaSol(data.factor_planta_sol);
            setFactorPlantaViento(data.factor_planta_viento);
            setPorcentajeSolar(data.porcentaje_solar);
            setPorcentajeEolico(data.porcentaje_eolico);

            setPorcentajeOpexFijo(data.porcentaje_opex_fijo);
            setPorcentajeOpexDirecto(data.porcentaje_opex_directo);
            setPorcentajeHidrogeno(data.porcentaje_hidrogeno);
            setPorcentajeCO2(data.porcentaje_co2);
            setPorcentajeElectricidad(data.porcentaje_electricidad);
            setPorcentajeCapex(data.porcentaje_capex);
            setCantidadPaneles(data.cantidad_paneles);
            setCantidadAerogeneradores(data.cantidad_aerogeneradores);
            setMwSolInstalados(data.mw_sol_instalados);
            setMwVientoInstalados(data.mw_viento_instalados);
            setAreaTerreno(data.area_terreno);
            setBateriaCapacidad(data.capacidad_bateria);
            setPotenciaReactor(data.potencia_reactor);
            setEnergiaNoAlmacenadaAnual(data.energia_no_almacenada_anual);
            setPorcentajeHorasAnualesReactorNoAlCien(data.porcentaje_hrs_anuales_reactor_no_funciona_alcien);
            setPorcentajeHorasAnualesSinEnergiaSuficienteReactor(data.porcentaje_hrs_anuales_sin_energia_suficiente_para_reactor);



            setCargando(false);
            setIsDataLoaded(true);
            console.log(data);// Aquí se actualiza el estado con el resultado de efuel.

        })
        .catch(error => {
            console.error("Hubo un error al obtener los datos:", error);
            setCargando(false);  // Finalizar la carga en caso de error
        });
        
    };


    
    const establecerValoresPorDefecto = () => {
        setFuente('Hibrido');
        setSolarMW('10');
        setVientoMW('10');
        setLongitud(longitud); // o cualquier valor por defecto
        setLatitud(latitud);
        setArea(area); // o cualquier valor por defecto
        setPrecioH2('2');
        setPrecioH2O('0'); 
        setPrecioCO2('45')
        setCombustible(''); // establece un valor por defecto si es necesario
        setPrecioElectricidad('50');
        setPerdidas('14');
        setInclinacionMaximaHSAT('45');
        setAzimut('0');
        setTipoArreglo('hsat');
        setFactorPerdidas('1');
        setAlturaSuperficie('80')
        setCobertura('glass')
        setModeloAerogenerador('Siemens SWT-3.6-120')
        setAeros('0')
        setAñosProyecto('30')
        setDisponibilidad('97')
        setTasaDescuento('7')
        setRadio1('25')
        setNumeroCiclos('3500')
        setEficienciaBateria('97')
        setSelfDischarge('0.008')
        
    };
    


    return (
        <div className="flex justify-center w-full max-h-screen overflow-y-scroll">
        <div className="bg-white p-6 w-full border-r">

            {activeSection === 'zonificador' && (
                <div>
                    <Zonificador
                        value={radio1}
                        onChange={(e) => setRadio1(e.target.value)}
                        onRadiusChange={handleRadiusChange}
                        setRadiusDisponibilidadAguaReciclada={setRadiusDisponibilidadAguaReciclada}
                        radiusDisponibilidadAguaReciclada={radiusDisponibilidadAguaReciclada}
                        setRadiusSubestaciones={setRadiusSubestaciones}
                        radiusSubestaciones={radiusSubestaciones}
                        setRadiusTransmision={setRadiusTransmision}
                        radiusTransmision={radiusTransmision}
                        setRadiusOleoductos={setRadiusOleoductos}
                        radiusOleoductos={radiusOleoductos}
                        setRadiusPuertos={setRadiusPuertos}
                        radiusPuertos={radiusPuertos}
                        setRadiusEstanquesCom={setRadiusEstanquesCom}
                        radiusEstanquesCom={radiusEstanquesCom}
                        setRadiusFuentesCo2={setRadiusFuentesCo2}
                        radiusFuentesCo2={radiusFuentesCo2}
                        /> 
                    <Cargando calcularResultado={calcularResultado} establecerValoresPorDefecto={establecerValoresPorDefecto} cargando={cargando}/>

                </div>
            )}
       

        {activeSection === 'produccion' && (
            <div>
            <Produccion 
            combustible={combustible}
            setCombustible={setCombustible}
            hideHydrolysisResults={hideHydrolysisResults}
            setHideHydrolysisResults={setHideHydrolysisResults}
            fuente={fuente}
            setFuente={setFuente}
            solarMW={solarMW}
            setSolarMW={setSolarMW}
            vientoMW={vientoMW}
            setVientoMW={setVientoMW}
            isDelimitingArea={isDelimitingArea}
            solarPercentage={solarPercentage}
            setSolarPercentage={setSolarPercentage}
            windPercentage={windPercentage}
            setWindPercentage={setWindPercentage}
            longitud={longitud}
            setLongitud={setLongitud}
            latitud={latitud}
            setLatitud={setLatitud}
            areaState={areaState}
            setArea={setArea}
            calcularResultado={calcularResultado}
            establecerValoresPorDefecto={establecerValoresPorDefecto}
            cargando={cargando}
            modeloAerogenerador={modeloAerogenerador}
            setModeloAerogenerador={setModeloAerogenerador}
            factorPerdidas={factorPerdidas}
            setFactorPerdidas={setFactorPerdidas}
            alturaSuperficie={alturaSuperficie}
            setAlturaSuperficie={setAlturaSuperficie}
            tipoArreglo={tipoArreglo}
            setTipoArreglo={setTipoArreglo}
            azimut={azimut}
            setAzimut={setAzimut}
            inclinacionMaximaHSAT={inclinacionMaximaHSAT}
            setInclinacionMaximaHSAT={setInclinacionMaximaHSAT}
            anguloElevacion={anguloElevacion}
            setAnguloElevacion={setAnguloElevacion}
            cobertura={cobertura}
            setCobertura={setCobertura}
            perdidas={perdidas}
            setPerdidas={setPerdidas}
            showInfo={showInfo}
            toggleInfo={toggleInfo}
            distanciaPuerto={distanciaPuerto}
            setNumeroCiclos={setNumeroCiclos}
            numeroCiclos={numeroCiclos}
            setEficienciaBateria={setEficienciaBateria}
            eficienciaBateria={eficienciaBateria}
            setSelfDischarge={setSelfDischarge}
            selfDischarge={selfDischarge}
            
            />
            <Cargando calcularResultado={calcularResultado} establecerValoresPorDefecto={establecerValoresPorDefecto} cargando={cargando}/>
            </div>
            )}



            {activeSection === 'costos' && (
            <div>
            <Costos hideHydrolysisResults={hideHydrolysisResults} 
            precioH2={precioH2} 
            setPrecioH2={setPrecioH2} 
            precioH2O={precioH2O} 
            setPrecioH2O={setPrecioH2O}  
            precioCo2={precioCo2} 
            setPrecioCO2={setPrecioCO2}
            precioelectricidad={precioelectricidad}  
            setPrecioElectricidad={setPrecioElectricidad}
            disponibilidad= {disponibilidad}
            setDisponibilidad={setDisponibilidad}
            añosProyecto= {añosProyecto}
            setAñosProyecto={setAñosProyecto}
            tasaDescuento={tasaDescuento}
            setTasaDescuento={setTasaDescuento}
            />
            <Cargando calcularResultado={calcularResultado} establecerValoresPorDefecto={establecerValoresPorDefecto} cargando={cargando}/>
            </div>
            )}
            

            {activeSection === 'resultados' && (
            <div>
              <Resultados
              longitudState={longitudState}
              latitudState={latitudState}
              paneles={paneles}
              aeros={aeros}
              solarMW={solarMW}
              vientoMW={vientoMW}
              factorPlanta={factorplanta}
              area={area}
              hideHydrolysisResults={hideHydrolysisResults}
              jetfuelsinh={jetfuelsinh}
              h2osinh={h2osinh}
              h2_sin_electrolisis={h2_sin_electrolisis}
              co2sinh={co2sinh}
              lcof_jetfuel_sinh={lcof_jetfuel_sinh}
              areaState={areaState}
              graph={graph}
              setGraph= {setGraph}
              graphsinh={graphsinh}
              setGraphSinh={setGraphSinh}
              distanciaPuerto={distanciaPuerto}
              distanciaEstanquesComb={distanciaEstanquesComb}
              distanciaFuentesCo2={distanciaFuentesCo2}
              distanciaMatriz={distanciaMatriz}
              distanciaOleoductos={distanciaOleoductos}
              puntaje_ranking={puntaje_ranking}
              porcentajes_zonificador={porcentajes_zonificador}
              desviacion_estandar_factor_planta={desviacion_estandar_factor_planta}
              arreglo_factor_planta_horario= {arreglo_factor_planta_horario}
              arreglo_factor_planta_mensual= {arreglo_factor_planta_mensual}

              produccion_minima_mensual_jetfuel_sin_electrolisis= {produccion_minima_mensual_jetfuel_sin_electrolisis}
              produccion_maxima_mensual_jetfuel_sin_electrolisis= {produccion_maxima_mensual_jetfuel_sin_electrolisis}
              desviacion_estandar_jetfuel_sin_electrolisis= {desviacion_estandar_jetfuel_sin_electrolisis}
              arreglo_produccion_horaria_jetfuel_sin_electrolisis= {arreglo_produccion_horaria_jetfuel_sin_electrolisis}
              arreglo_produccion_mensual_co2_sin_electrolisis= {arreglo_produccion_mensual_co2_sin_electrolisis}
              arreglo_produccion_horaria_co2_sin_electrolisis= {arreglo_produccion_horaria_co2_sin_electrolisis}
              arreglo_produccion_mensual_h2o_sin_electrolisis= {arreglo_produccion_mensual_h2o_sin_electrolisis}
              arreglo_produccion_horaria_h2o_sin_electrolisis={arreglo_produccion_horaria_h2o_sin_electrolisis}
              arreglo_produccion_mensual_h2_sin_electrolisis= {arreglo_produccion_mensual_h2_sin_electrolisis}
              arreglo_produccion_horaria_h2_sin_electrolisis={arreglo_produccion_horaria_h2_sin_electrolisis}
              factor_planta_sol= {factor_planta_sol}
              factor_planta_viento= {factor_planta_viento}
              porcentaje_solar= {porcentaje_solar}
              porcentaje_eolico= {porcentaje_eolico}
              porcentajeOpexFijo={porcentajeOpexFijo}
              porcentajeOpexDirecto={porcentajeOpexDirecto}
              porcentajeHidrogeno={porcentajeHidrogeno}
              porcentajeCO2={porcentajeCO2}
              porcentajeElectricidad={porcentajeElectricidad}
              porcentajeCapex={porcentajeCapex}
              areaTerreno={areaTerreno}
              cantidadPaneles={cantidadPaneles}
              cantidadAerogeneradores={cantidadAerogeneradores}
              mwSolInstalados={mwSolInstalados}
              mwVientoInstalados={mwVientoInstalados}
              bateriaCapacidad={bateriaCapacidad}
              potenciaReactor={potenciaReactor}
              energiaNoAlmacenadaAnual={energiaNoAlmacenadaAnual}
              porcentajeHorasAnualesReactorNoAlCien={porcentajeHorasAnualesReactorNoAlCien}
              porcentajeHorasAnualesSinEnergiaSuficienteReactor={porcentajeHorasAnualesSinEnergiaSuficienteReactor}
              
              />
              </div>
                )}
            
            </div>
        </div>
        
    );
}





function Calculadora({ latitud, 
    longitud,
    area, 
    isDelimitingArea,
    activeSection,
    setActiveSection,
    setRadiusDisponibilidadAguaReciclada,
    radiusDisponibilidadAguaReciclada,
    setRadiusOleoductos,
    radiusOleoductos,
    setRadiusTransmision,
    radiusTransmision,
    setRadiusSubestaciones,
    radiusSubestaciones,
    setRadiusPuertos,
    radiusPuertos,
    setRadiusFuentesCo2,
    radiusFuentesCo2,
    setRadiusEstanquesCom,
    radiusEstanquesCom,
    distanciaPuerto,
    distanciaEstanquesComb,
    distanciaFuentesCo2,
    distanciaMatriz,
    distanciaOleoductos
    

}) {
    
    const [resultado, setResultado] = useState(0);
    const [paneles, setPaneles] = useState(null);
    const [mwdisponiblesol, setMwdisponiblesol] = useState(0);
    const [mwdisponibleviento, setMwdisponibleviento] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [hideHydrolysisResults, setHideHydrolysisResults] = useState(false);

    useEffect(() => {
        if (isDataLoaded) {
            setActiveSection('resultados'); // Cambia al componente de resultados
        }
    }, [isDataLoaded, setActiveSection]);

    return (
        <div className="min-h-screen bg-white ">
                <div className="mb-2  ml-2 flex ">
                <button className="bg-black text-secondary h-35 px-4 py-2 rounded-b" onClick={() => setActiveSection('zonificador')}>Zonificador</button>
                <button className="bg-black text-secondary ml-5 px-4 py-2 rounded-b" onClick={() => setActiveSection('produccion')}>Producción</button>
                <button className="bg-black text-secondary ml-5 px-4 py-2 rounded-b" onClick={() => setActiveSection('costos')}>Costos</button>
                <button className="bg-black text-secondary ml-5 px-4 py-2 rounded-b" onClick={() => setActiveSection('resultados')}>Resultados</button> 
                </div>


                
                
                <Cotizacion1 
                    activeSection={activeSection}
                    latitud={latitud} 
                    longitud={longitud} 
                    area={area}
                    isDelimitingArea={isDelimitingArea}
                    setIsDataLoaded={setIsDataLoaded}
                    setResultado={setResultado}
                    setPaneles={setPaneles}
                    setMwdisponiblesol={setMwdisponiblesol}
                    setMwdisponibleviento={setMwdisponibleviento}
                    setCargando={setCargando}
                    hideHydrolysisResults={hideHydrolysisResults}
                    setHideHydrolysisResults={setHideHydrolysisResults}
                    setRadiusDisponibilidadAguaReciclada={setRadiusDisponibilidadAguaReciclada}
                    radiusDisponibilidadAguaReciclada={radiusDisponibilidadAguaReciclada}
                    setRadiusSubestaciones={setRadiusSubestaciones} 
                    radiusSubestaciones={radiusSubestaciones}
                    setRadiusTransmision={setRadiusTransmision}
                    radiusTransmision={radiusTransmision}
                    setRadiusOleoductos={setRadiusOleoductos} 
                    radiusOleoductos={radiusOleoductos}
                    setRadiusEstanquesCom={setRadiusEstanquesCom}
                    radiusEstanquesCom={radiusEstanquesCom}
                    setRadiusPuertos={setRadiusPuertos}
                    radiusPuertos={radiusPuertos}
                    setRadiusFuentesCo2={setRadiusFuentesCo2}
                    radiusFuentesCo2={radiusFuentesCo2}
                    distanciaPuerto={distanciaPuerto}
                    distanciaEstanquesComb={distanciaEstanquesComb}
                    distanciaOleoductos={distanciaOleoductos}
                    distanciaFuentesCo2={distanciaFuentesCo2}
                    distanciaMatriz={distanciaMatriz}
                    


                    />
                
                
                

            
                
                   
        </div>
    );
}

export default Calculadora;