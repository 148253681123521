import React, { useState } from 'react';

// Componente InfoIcon
const InfoIcon = ({ text }) => (
  <div className="mr-1 relative group" aria-label={text} role="tooltip">
    <span className="text-secondary cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        className="w-5 h-5"
      >
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-10 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v2h2V7zm0 4h-2v6h2v-6z" />
      </svg>
    </span>
    <div className="absolute left-0 mt-1 w-40 p-2 text-sm text-white bg-secondary rounded-lg opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none z-50">
      {text}
    </div>
  </div>
);

// Componente BarraCheckbox con InfoIcon
const BarraCheckbox = ({ radio, setRadio, titulo, onRadiusChange, infoText }) => {
  // Función para manejar cambios en el checkbox
  const handleCheckboxChange = (setRadiusFunc) => (e) => {
    const isChecked = e.target.checked;
    const newRadius = isChecked ? 1 : 0;
    setRadiusFunc(newRadius);
    onRadiusChange(newRadius);
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-black mb-1 flex items-center">
        <InfoIcon text={infoText} />
        {titulo}
      </label>
      <input
        type="checkbox"
        className="toggle [--tglbg:#69E995] bg-white hover:bg-white border-white"
        checked={radio > 0}
        onChange={handleCheckboxChange(setRadio)}
      />
      <div className="text-center">{radio > 0 ? 'Mostrando' : 'Oculto'}</div>
    </div>
  );
};

// Componente Barra con InfoIcon
const Barra = ({ radio, setRadio, titulo, onRadiusChange, infoText }) => {
  const handleRadiusChange = (setRadiusFunc) => (e) => {
    const newRadius = e.target.value;
    setRadiusFunc(newRadius);
    onRadiusChange(newRadius);
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-black mb-1 flex items-center">
        <InfoIcon text={infoText} />
        {titulo}
      </label>
      <input
        type="range"
        min={0}
        max="250"
        value={radio}
        onChange={handleRadiusChange(setRadio)}
        className="range [--range-shdw:#69E995]"
      />
      <div className="text-center">{radio} km</div>
    </div>
  );
};

const Zonificador = ({
  onRadiusChange,
  setRadiusOleoductos,
  radiusOleoductos,
  setRadiusTransmision,
  radiusTransmision,
  setRadiusPuertos,
  radiusPuertos,
  setRadiusFuentesCo2,
  radiusFuentesCo2,
  setRadiusEstanquesCom,
  radiusEstanquesCom,
}) => {
  return (
    <div>
      <label className="block text-xl font-medium text-gray-700 mb-1">Zonificador</label>
      <hr className="my-2 border-secondary" />
      <div className="grid grid-cols-3 gap-4 mb-6">
        {/* Columna 1: Ubicación */}
        <div>
          <label className="block text-lg font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Configuración de las zonas de influencia para la ubicación." />
            Ubicación
          </label>
          <Barra
            radio={radiusFuentesCo2}
            setRadio={setRadiusFuentesCo2}
            titulo="Fuentes CO₂"
            onRadiusChange={onRadiusChange}
            infoText="Zona de influencia alrededor de las fuentes de CO₂, como plantas de carbón, cemento, siderúrgicas y fermentación."
          />
          <BarraCheckbox
            radio={radiusOleoductos}
            setRadio={setRadiusOleoductos}
            titulo="Oleoductos"
            onRadiusChange={onRadiusChange}
            infoText="Zona de influencia alrededor de los oleoductos cercanos."
          />
          <BarraCheckbox
            radio={radiusTransmision}
            setRadio={setRadiusTransmision}
            titulo="Transmisión Energética"
            onRadiusChange={onRadiusChange}
            infoText="Zona de influencia de las líneas de transmisión energética cercanas."
          />
        </div>

        {/* Columna 2: Uso final */}
        <div>
          <label className="block text-lg font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Configuración de las zonas de influencia para el uso final del eFuel." />
            Uso final
          </label>
          <Barra
            radio={radiusPuertos}
            setRadio={setRadiusPuertos}
            titulo="Puertos"
            onRadiusChange={onRadiusChange}
            infoText="Zona de influencia alrededor de los puertos con posibilidad de exportar eFuel."
          />
        </div>

        {/* Columna 3: Almacenamiento */}
        <div>
          <label className="block text-lg font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Configuración de las zonas de influencia para el almacenamiento de eFuel." />
            Almacenamiento
          </label>
          <Barra
            radio={radiusEstanquesCom}
            setRadio={setRadiusEstanquesCom}
            titulo="Estanques Combustible"
            onRadiusChange={onRadiusChange}
            infoText="Zona de influencia alrededor de los estanques de combustible para almacenamiento."
          />
        </div>
      </div>
    </div>
  );
};

export default Zonificador;
