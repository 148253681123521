import React, { useState, useEffect } from 'react';

const InfoIcon = ({ text }) => (
  <div className="mr-1 relative group">
    <span className="text-secondary cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        className="w-5 h-5"
      >
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-10 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v2h2V7zm0 4h-2v6h2v-6z"></path>
      </svg>
    </span>
    <div className="absolute left-0 mt-1 w-40 p-1 text-sm text-white bg-secondary rounded-lg opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none z-50">
      {text}
    </div>
  </div>
);

const InfoBanner = ({ onClose }) => (
  <div className="bg-secondary text-white p-4 rounded-lg shadow-lg mb-4">
    <div className="flex justify-between items-center">
      <span>
        En la esquina superior izquierda del mapa puedes seleccionar "Delimitar una
        zona" o "Marcar un punto".
      </span>
      <button onClick={onClose} className="text-lg font-bold ml-4 cursor-pointer">
        ✖
      </button>
    </div>
  </div>
);

const Produccion = ({
  combustible,
  setCombustible,
  fuente,
  setFuente,
  solarMW,
  setSolarMW,
  vientoMW,
  setVientoMW,
  isDelimitingArea,
  solarPercentage,
  setSolarPercentage,
  windPercentage,
  setWindPercentage,
  longitud,
  setLongitud,
  latitud,
  setLatitud,
  areaState,
  setArea,
  calcularResultado,
  establecerValoresPorDefecto,
  cargando,
  modeloAerogenerador,
  setModeloAerogenerador,
  factorPerdidas,
  setFactorPerdidas,
  alturaSuperficie,
  setAlturaSuperficie,
  tipoArreglo,
  setTipoArreglo,
  azimut,
  setAzimut,
  inclinacionMaximaHSAT,
  setInclinacionMaximaHSAT,
  anguloElevacion,
  setAnguloElevacion,
  cobertura,
  setCobertura,
  perdidas,
  setPerdidas,
  showInfo,
  toggleInfo,
  setNumeroCiclos,
  numeroCiclos,
  setEficienciaBateria,
  eficienciaBateria,
  setSelfDischarge,
  selfDischarge,
  setBateriaCapacidad,
}) => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Mostrar el banner al cargar la página
    setShowBanner(true);
  }, []);

  return (
    <div>
      {/* Banner informativo */}
      {showBanner && <InfoBanner onClose={() => setShowBanner(false)} />}

      {/* Título */}
      <label className="block text-xl font-medium text-gray-700 mb-1">
        Producción
    </label>
    <hr className="my-2 border-secondary" />

    {/* Grid de Inputs */}
    <div className="grid grid-cols-3 gap-4 mb-6">
      {/* Columna 1: eFuel */}
      <div>
        <label className="block text-lg font-medium text-gray-700 mb-1">
          eFuel
        </label>
        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
          <InfoIcon text="Selecciona el tipo de combustible utilizado para la producción de eFuel." />
          Combustible
        </label>
        <select
          value={combustible}
          onChange={(e) => setCombustible(e.target.value)}
          className="border border-secondary rounded w-full py-2 px-3 mb-2"
        >
          <option value="combustible1">Jet-Fuel</option>
          <option value="ethanol" disabled>
            🔒 Ethanol
          </option>
          <option value="methanol" disabled>
            🔒 Methanol
          </option>
          <option value="diesel" disabled>
            🔒 Diesel
          </option>
        </select>
      </div>

      {/* Columna 2: Fuente de Energía */}
      <div>
        <label className="block text-lg font-medium text-gray-700 mb-1">
          Fuente
        </label>
        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
          <InfoIcon text="Selecciona la fuente principal de energía para la producción." />
          Fuente de energía
        </label>
        <select
          value={fuente}
          onChange={(e) => setFuente(e.target.value)}
          className="border border-secondary rounded w-full py-2 px-3 mb-2"
        >
          <option value="Hibrido">Híbrido</option>
          <option value="solar">Solar</option>
          <option value="viento">Viento</option>
        </select>

        {!isDelimitingArea && fuente === 'Hibrido' && (
          <>
            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
              <InfoIcon text="Capacidad instalada de energía solar en megavatios." />
              Solar MW
            </label>
            <input
              type="text"
              value={solarMW}
              onChange={(e) => setSolarMW(e.target.value)}
              className="border border-secondary rounded w-full py-2 px-3 mb-2"
            />

            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
              <InfoIcon text="Capacidad instalada de energía eólica en megavatios." />
              Viento MW
            </label>
            <input
              type="text"
              value={vientoMW}
              onChange={(e) => setVientoMW(e.target.value)}
              className="border border-secondary rounded w-full py-2 px-3 mb-2"
            />
          </>
        )}

        {!isDelimitingArea && fuente === 'solar' && (
          <>
            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
              <InfoIcon text="Capacidad instalada de energía solar en megavatios." />
              Solar MW
            </label>
            <input
              type="text"
              value={solarMW}
              onChange={(e) => setSolarMW(e.target.value)}
              className="border border-secondary rounded w-full py-2 px-3 mb-2"
            />
          </>
        )}

        {!isDelimitingArea && fuente === 'viento' && (
          <>
            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
              <InfoIcon text="Capacidad instalada de energía eólica en megavatios." />
              Viento MW
            </label>
            <input
              type="text"
              value={vientoMW}
              onChange={(e) => setVientoMW(e.target.value)}
              className="border border-secondary rounded w-full py-2 px-3 mb-2"
            />
          </>
        )}

        {isDelimitingArea && (
          <>
            {fuente === 'Hibrido' && (
              <>
                <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                  <InfoIcon text="Porcentaje de la energía total que proviene de la energía solar." />
                  Porcentaje Solar
                </label>
                <input
                  type="number"
                  value={solarPercentage}
                  onChange={(e) => setSolarPercentage(e.target.value)}
                  className="border border-secondary rounded w-full py-2 px-3 mb-2"
                />
                <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                  <InfoIcon text="Porcentaje de la energía total que proviene de la energía eólica." />
                  Porcentaje Viento
                </label>
                <input
                  type="number"
                  value={windPercentage}
                  onChange={(e) => setWindPercentage(e.target.value)}
                  className="border border-secondary rounded w-full py-2 px-3 mb-2"
                />
              </>
            )}
            {fuente === 'solar' && (
              <>
                <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                  <InfoIcon text="Porcentaje de la energía total que proviene de la energía solar. (Solo lectura)" />
                  Porcentaje Solar
                </label>
                <input
                  type="number"
                  value={solarPercentage}
                  readOnly
                  className="border border-secondary rounded w-full py-2 px-3 mb-2"
                />
              </>
            )}
            {fuente === 'viento' && (
              <>
                <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                  <InfoIcon text="Porcentaje de la energía total que proviene de la energía eólica. (Solo lectura)" />
                  Porcentaje Viento
                </label>
                <input
                  type="number"
                  value={windPercentage}
                  readOnly
                  className="border border-secondary rounded w-full py-2 px-3 mb-2"
                />
              </>
            )}
          </>
        )}
      </div>

      {/* Columna 3: Ubicación */}
      <div>
        <label className="block text-lg font-medium text-gray-700 mb-1">
          Ubicación
        </label>

        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
          <InfoIcon text="Coordenada de longitud del sitio." />
          Longitud
        </label>
        <input
          type="text"
          value={longitud}
          onChange={(e) => setLongitud(e.target.value)}
          className="border border-secondary rounded w-full py-2 px-3 mb-2"
        />

        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
          <InfoIcon text="Coordenada de latitud del sitio." />
          Latitud
        </label>
        <input
          type="text"
          value={latitud}
          onChange={(e) => setLatitud(e.target.value)}
          className="border border-secondary rounded w-full py-2 px-3 mb-2"
        />

        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
          <InfoIcon text="Área total en kilómetros cuadrados." />
          Área Km²
        </label>
        <input
          type="text"
          value={areaState / 1000000}
          onChange={(e) => setArea(e.target.value)}
          className="border border-secondary rounded w-full py-2 px-3 mb-2"
        />
      </div>

      {/* Columna 4: Aerogeneradores */}
      {fuente !== 'solar' && (
        <div>
          <label className="block text-lg font-medium text-gray-700 mb-1">
            Aerogeneradores
          </label>

          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Selecciona el modelo del aerogenerador utilizado." />
            Modelo Aerogenerador
          </label>
          <select
            value={modeloAerogenerador}
            onChange={(e) => setModeloAerogenerador(e.target.value)}
            className="border border-secondary rounded w-full py-2 px-3 mb-2"
          >
            <option value="Siemens SWT-3.6-120">
              Siemens SWT-3.6-120 (3600 kW, 120 m)
            </option>
            <option value="Sinovel SL3000/100">
              Sinovel SL3000/100 (3000 kW, 100 m)
            </option>
            <option value="Vestas V164 - 8.0 MW">
              Vestas V164 - 8.0 MW (8000 kW, 164 m)
            </option>
            <option value="Acciona AW 77/1500 Class III">
              Acciona AW 77/1500 Class III (1500 kW, 77 m)
            </option>
            <option value="SDeWind D8.2">
              DeWind D8.2 (2000 kW, 80 m)
            </option>
            <option value="Gamesa G128-4.5 MW">
              Gamesa G128-4.5 MW (4500 kW, 128 m)
            </option>
            <option value="Gamesa G132-5 MW">
              Gamesa G132-5 MW (5000 kW, 132 m)
            </option>
            <option value="Sinovel SL6000/128 Onshore">
              Sinovel SL6000/128 Onshore (6000 kW, 128 m)
            </option>
            <option value="WinWinD WWD-1-60">
              WinWinD WWD-1-60 (1000 kW, 60 m)
            </option>
            <option value="Vestas V90 - 3.0 MW 109.4 dB(A)">
              Vestas V90 - 3.0 MW 109.4 dB(A) (3000 kW, 90 m)
            </option>
          </select>

          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Factor de pérdidas asociado al aerogenerador." />
            Factor Pérdidas
          </label>
          <input
            type="number"
            value={factorPerdidas}
            onChange={(e) => {
              const newValue = Math.min(1, Math.max(0, parseFloat(e.target.value))) || 0;
              setFactorPerdidas(newValue.toString());
            }}
            min="0"
            max="1"
            step="0.01"
            className="border border-secondary rounded w-full py-2 px-3 mb-2"
          />

          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Altura de la superficie donde está instalado el aerogenerador." />
            Altura Superficie
          </label>
          <input
            type="number"
            value={alturaSuperficie}
            onChange={(e) => setAlturaSuperficie(e.target.value)}
            className="border border-secondary rounded w-full py-2 px-3 mb-2"
          />
        </div>
      )}

      {/* Columna 5: Fotovoltaico */}
      {fuente !== 'viento' && (
        <div>
          <label className="block text-lg font-medium text-gray-700 mb-1">
            Fotovoltaico
          </label>

          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Tipo de arreglo fotovoltaico utilizado en el sistema." />
            Tipo de Arreglo
          </label>
          <select
            value={tipoArreglo}
            onChange={(e) => setTipoArreglo(e.target.value)}
            className="border border-secondary rounded w-full py-2 px-3 mb-2"
          >
            <option value="hsat">HSAT</option>
            <option value="hor">Horizontal</option>
            <option value="tilt_2elev">2 inclinaciones por año</option>
            <option value="tilt">Inclinado</option>
            <option value="htsat">HTSAT</option>
            <option value="vsat">VSAT</option>
            <option value="tsat">TSAT</option>
            <option value="tracker_2axis">Seguimiento 2-ejes</option>
          </select>

          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Orientación horizontal del panel fotovoltaico." />
            Azimut
          </label>
          <select
            value={azimut}
            onChange={(e) => setAzimut(e.target.value)}
            className="border border-secondary rounded w-full py-2 px-3 mb-2"
          >
            <option value="0">0 = Norte</option>
            <option value="90">90 = Este</option>
            <option value="180">180 = Sur</option>
            <option value="270">270 = Oeste</option>
          </select>

          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Ángulo de elevación del panel fotovoltaico." />
            Ángulo de Elevación
          </label>
          <select
            value={anguloElevacion}
            onChange={(e) => setAnguloElevacion(e.target.value)}
            className="border border-secondary rounded w-full py-2 px-3 mb-2"
          >
            <option value="99">Óptimo a la latitud del sitio</option>
            <option value="90">90</option>
            <option value="70">70</option>
            <option value="45">45</option>
            <option value="15">15</option>
            <option value="0">0</option>
          </select>

          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Inclinación máxima permitida para el sistema HSAT." />
            Inclinación Máxima HSAT
          </label>
          <input
            type="number"
            value={inclinacionMaximaHSAT}
            onChange={(e) => {
              const newValue = Math.min(90, Math.max(0, parseFloat(e.target.value))) || 0;
              setInclinacionMaximaHSAT(newValue.toString());
            }}
            min="0"
            max="90"
            className="border border-secondary rounded w-full py-2 px-3 mb-2"
          />

          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Tipo de cobertura utilizada en el sistema fotovoltaico." />
            Cobertura
          </label>
          <select
            value={cobertura}
            onChange={(e) => setCobertura(e.target.value)}
            className="border border-secondary rounded w-full py-2 px-3 mb-2"
          >
            <option value="glass">Glass</option>
            <option value="anti-reflectante">Anti-reflectante</option>
          </select>

          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Porcentaje de pérdidas en el sistema fotovoltaico." />
            Porcentaje Pérdidas
          </label>
          <input
            type="number"
            value={perdidas}
            onChange={(e) => {
              const newValue = Math.min(100, Math.max(0, parseFloat(e.target.value))) || 0;
              setPerdidas(newValue.toString());
            }}
            min="0"
            max="100"
            className="border border-secondary rounded w-full py-2 px-3 mb-2"
          />
        </div>
      )}

      {/* Columna 6: Baterías */}
      <div>
        <label className="block text-lg font-medium text-gray-700 mb-1">
          Baterías
        </label>

        {/* Ciclos */}
        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
          <InfoIcon text="Número de ciclos que la batería puede soportar antes de perder capacidad." />
          Ciclos
        </label>
        <input
          type="text"
          value={numeroCiclos}
          onChange={(e) => setNumeroCiclos(e.target.value)}
          className="border border-secondary rounded w-full py-2 px-3 mb-2"
        />

        {/* Eficiencia */}
        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
          <InfoIcon text="Porcentaje de energía que la batería retiene después de cada carga y descarga." />
          Eficiencia (%)
        </label>
        <input
          type="text"
          value={eficienciaBateria}
          onChange={(e) => setEficienciaBateria(e.target.value)}
          className="border border-secondary rounded w-full py-2 px-3 mb-2"
        />

        {/* Auto-Descarga */}
        <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
          <InfoIcon text="Porcentaje de energía que la batería pierde cada día debido a la auto-descarga." />
          Auto-Descarga (%/day)
        </label>
        <input
          type="text"
          value={selfDischarge}
          onChange={(e) => setSelfDischarge(e.target.value)}
          className="border border-secondary rounded w-full py-2 px-3 mb-2"
        />
      </div>
    </div>
  </div>
);
};

export default Produccion;

