import React from "react";

// Componente InfoIcon
const InfoIcon = ({ text }) => (
  <div className="mr-1 relative group">
    <span className="text-secondary cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        className="w-5 h-5"
      >
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-10 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v2h2V7zm0 4h-2v6h2v-6z" />
      </svg>
    </span>
    <div className="absolute left-0 mt-1 w-40 p-2 text-sm text-white bg-secondary rounded-lg opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none z-50">
      {text}
    </div>
  </div>
);

export const Costos = ({
  hideHydrolysisResults,
  precioH2,
  setPrecioH2,
  precioH2O,
  setPrecioH2O,
  precioCo2,
  setPrecioCO2,
  precioelectricidad,
  setPrecioElectricidad,
  disponibilidad,
  setDisponibilidad,
  añosProyecto,
  setAñosProyecto,
  tasaDescuento,
  setTasaDescuento,
}) => {
  return (
    <div>
      {/* Título Principal */}
      <label className="block text-xl font-medium text-gray-700 mb-1">Costos</label>
      <hr className="my-2 border-secondary" />

      {/* Grid de Inputs */}
      <div className="grid grid-cols-3 gap-4 mb-6">
        {/* Columna 1: Entrada del Proceso */}
        <div>
          <label className="block text-lg font-medium text-gray-700 mb-1">
            Entrada del proceso
          </label>

          {/* Precio H₂ (USD/kg) */}
          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Precio del hidrógeno (H₂) por kilogramo en USD." />
            Precio H<sub>2</sub> (USD/kg)
          </label>
          <input
            type="text"
            value={precioH2}
            onChange={(e) => setPrecioH2(e.target.value)}
            className="border border-secondary rounded w-full py-2 px-3 mb-2"
          />

          {/* Precio H₂O (USD/m³) */}
          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Precio del agua (H₂O) por metro cúbico en USD." />
            Precio H<sub>2</sub>O (USD/m³)
          </label>
          <input
            type="text"
            value={precioH2O}
            onChange={(e) => setPrecioH2O(e.target.value)}
            className="border border-secondary rounded w-full py-2 px-3 mb-2"
          />

          {/* Precio CO₂ (USD/tonne) */}
          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Precio del dióxido de carbono (CO₂) por tonelada en USD." />
            Precio CO<sub>2</sub> (USD/tonne)
          </label>
          <input
            type="text"
            value={precioCo2}
            onChange={(e) => setPrecioCO2(e.target.value)}
            className="border border-secondary rounded w-full py-2 px-3 mb-2"
          />

          {/* Electricidad (USD/MWh) */}
          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Costo de la electricidad por megavatio hora en USD." />
            Electricidad (USD/MWh)
          </label>
          <input
            type="text"
            value={precioelectricidad}
            onChange={(e) => setPrecioElectricidad(e.target.value)}
            className="border border-secondary rounded w-full py-2 px-3"
          />
        </div>

        {/* Columna 2: Evaluación del Proyecto */}
        <div>
          <label className="block text-lg font-medium text-gray-700 mb-1">
            Evaluación del proyecto
          </label>

          {/* Disponibilidad (%) */}
          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Porcentaje de tiempo que el proyecto está disponible para operar." />
            Disponibilidad (%)
          </label>
          <input
            type="text"
            value={disponibilidad}
            onChange={(e) => setDisponibilidad(e.target.value)}
            className="border border-secondary rounded w-full py-2 px-3 mb-2"
          />

          {/* Años Proyecto (años) */}
          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Duración total del proyecto en años." />
            Años proyecto (años)
          </label>
          <input
            type="text"
            value={añosProyecto}
            onChange={(e) => setAñosProyecto(e.target.value)}
            className="border border-secondary rounded w-full py-2 px-3 mb-2"
          />

          {/* Tasa Descuento (%) */}
          <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
            <InfoIcon text="Tasa de descuento anual aplicada al flujo de caja del proyecto." />
            Tasa Descuento (%)
          </label>
          <input
            type="text"
            value={tasaDescuento}
            onChange={(e) => setTasaDescuento(e.target.value)}
            className="border border-secondary rounded w-full py-2 px-3"
          />
        </div>
      </div>
    </div>
  );
};
